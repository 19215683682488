import React, { useState } from 'react';
import { PrimaryButton } from '../../../../components';
import DownloadAppModal from '../../../../components/DownloadAppModal/DownloadAppModal';
import css from './InfoBox.module.scss';
import { PortableText } from '@portabletext/react';
interface IProps {
  question: string;
  heading: string;
  text: any;
  buttonText: string;
  buttonLink?: string;
  downloadAppLink?: boolean;
  showButton?: boolean;
}


const InfoBox = (props: IProps) => {
  const [downloadAppModal, setDownloadAppModal] = useState(false);
  const handleModalDownloadApp = () => {
    setDownloadAppModal(prev => !prev);
  };
  const {
    question,
    heading,
    text,
    buttonText,
    buttonLink,
    downloadAppLink,
    showButton,
  } = props;

  return (
    <div className={css.infoBoxContainer}>
      {question ? <div className={css.question}>{question}</div> : null}
      <h1 className={css.infoHeading}>{heading}</h1>
      <div className={css.infoText}>
     
        <PortableText value={text} />
      </div>
      <div className={css.buttonContainer}>
        {showButton ? (
          downloadAppLink ? (
            <PrimaryButton onClick={() => handleModalDownloadApp()}>
              {buttonText}
            </PrimaryButton>
          ) : (
            <a href={'/' + buttonLink}>
              <PrimaryButton>{buttonText}</PrimaryButton>
            </a>
          )
        ) : null}
      </div>
      <DownloadAppModal
        isOpen={downloadAppModal}
        onClose={() => handleModalDownloadApp()}
      />
    </div>
  );
};

export default InfoBox;
